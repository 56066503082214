<!--
 * @Description: 
 * @Author: 六六
 * @Date: 2021-01-20 14:58:52
 * @LastEditTime: 2021-01-27 17:16:20
 * @有问题找百度，实在不行就删库
-->
<template>
  <div id="app" :style="isPhone ? '' : 'max-width:640px'">
    <router-view v-wechat-title="$route.meta.title"></router-view>
    <div class="dialog-overlay" @touchmove.prevent v-if="hengPing">
      <div class="overlay-content">
        <img src="./assets/images/overlay.png" alt="" />
        <div class="text">
          为了您的良好体验<br />
          请将手机/平板竖屏操作
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogShow: false,
      value: false,
      hengPing: false,
      isPhone: true,
    };
  },

  mounted() {
    this._isMobile();
    this.isPhone = this.$store.getters['userInfo/isPhone']
    if (this.isPhone) {
      window.addEventListener("resize", this.renderResize, false)
    }

  },
  methods: {
    renderResize() {
      // 判断横竖屏
      let width = document.documentElement.clientWidth
      let height = document.documentElement.clientHeight
      if (width > height) {
        this.hengPing = true;
      } else {
        this.hengPing = false;
      }
      // 做页面适配
      // 注意：renderResize 方法执行时虚拟 dom 尚未渲染挂载，如果要操作 vue 实例，最好在this.$nextTick()里进行。
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        this.$store.commit("userInfo/SET_FLAG", true);
      } else {
        this.$store.commit("userInfo/SET_FLAG", false);
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.renderResize, false)
  }
};
</script>
<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  font-size: 12px;
  margin: 0 auto;
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    font-size: 12px;
  }

  .dialog-overlay {
    background-color: rgba(0, 0, 0, 1);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    .overlay-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 50px;
      }
      .text {
        font-size: 18px;
        color: #626262;
      }
    }
  }
}
</style>

/*
 * @Description: 
 * @Author: 六六
 * @Date: 2021-01-20 14:58:52
 * @LastEditTime: 2021-01-25 18:08:12
 * @有问题找百度，实在不行就删库
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import VueWechatTitle from 'vue-wechat-title'

Vue.use(VueWechatTitle)

import { urls } from './util/request'
Vue.prototype.$urls = urls;

import { Button } from 'vant';
import { Col, Row } from 'vant';
import { Grid, GridItem } from 'vant';
import { Icon } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';

Vue.use(Toast);
Vue.use(Field);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Icon);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

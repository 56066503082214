/*
 * @Description: request请求拦截器
 * @Date: 2019-09-23 14:41:48
 * @Author: 张恒
 * @LastEditTime: 2021-01-22 13:51:42
 * @如果有bug，那肯定不是我的锅
 */
import axios from "axios";
import qs from 'qs';

let urls = 'https://h5.niutrans.com';
export { urls };

// 创建axios实例
const request = axios.create({
  // baseURL: '', // api接口地址
  baseURL: urls + "/NiuTransWeChatPublic", // api接口地址
  // BASE.url
  timeout: 1000 * 10, // 请求超时时间（毫秒）
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    'X-Requested-With': 'XMLHttpRequest'
  }
});

// request请求拦截器
// request.interceptors.request.use(
//   config => {
//     // loadingRequest;
//     // 将token给到一个前后台约定好的key中，作为请求发送
//     let token = '1'
//     if (token) {
//       config.headers.Authorization = token;
//     }
//     return config;
//   },
//   error => {
//     // console.log("错误", error); // for debug
//     return Promise.reject(error);
//   }
// );

// respone响应拦截器
// request.interceptors.response.use(
//   response => {
//     if (response.status === 200) {
//       // loadingRequest.close();
//       return response;
//     } else {
//     }
//     return Promise.reject(response);
//   },
//   error => {
//     // 返回status不为200的错误处理
//     if (error.response) {

//       switch (error.response.status) {
//         case 401: {
//           Message.error("登录已过期，请重新登录");
//           setTimeout(() => {
//             toLogin();
//           }, 1000);
//           break;
//         }
//         default: {
//           Message.error("连接失败");
//           break;
//         }
//       }
//     } else {
//       Message.error("连接失败，请检查网络");
//     }
//     return Promise.reject(error);
//   }
// );



// get封装方法
export function get(url, params) {
  return new Promise((resolve, reject) => {
    request
      .get(url, { params: params })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

// post封装方法
export function post(url, params) {
  return new Promise((resolve, reject) => {
    request
      .post(url, qs.stringify(params))
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}


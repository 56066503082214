/*
 * @Description: 
 * @Author: 六六
 * @Date: 2021-01-20 14:58:52
 * @LastEditTime: 2021-01-25 18:10:14
 * @有问题找百度，实在不行就删库
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'activity',
    component: () => import('../views/activity.vue'),
    meta: {
      title: '小牛翻译新春盲盒'
    }
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('../views/questionnaire.vue'),
    meta: {
      title: '问卷调查'
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: 'zhuli',
  routes
})

export default router

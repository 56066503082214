/*
 * @Description: 
 * @Author: 六六
 * @Date: 2021-01-25 09:20:03
 * @LastEditTime: 2021-01-27 17:11:24
 * @有问题找百度，实在不行就删库
 */
const userInfo = {
  namespaced: true,
  state: {
    userForm: {},
    stateVal: "",
    isPhone: true
  },
  mutations: {
    SET_STATE(state, val) {
      state.stateVal = val;
    },
    SET_FLAG(state, val) {
      state.isPhone = val;
    },
  },
  getters: {
    stateVal: state => state.stateVal,
    isPhone: state => state.isPhone
  },
  actions: {

  }
}


export default userInfo
